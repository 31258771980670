import httpRequest from './index';
// 获取列表信息
function GetList(data) {
  return httpRequest({
    method: 'POST',
    url: '/api/Project/GetListFromPublic',
    data
  });
}
// 删除
function DeleteProject(data) {
  return httpRequest({
    method: 'POST',
    url: '/api/Project/Delete',
    data
  });
}
// 获取平屋顶项目详情
async function GetProjectDetail(data) {
  return await httpRequest({
    method: 'POST',
    url: '/api/ProjectFlatRoof/GetInfo?ProjectId=' + data
  });
}

// 获取斜屋顶项目详情
async function GetCADDetailInfo(data) {
  return await httpRequest({
    method: 'POST',
    url: '/api/ObliqueRoof/GetCADDetailInfo?projectId=' + data
  });
}

// 获取复合屋顶项目详情
async function GetCADComplexDetailInfo(data) {
  return await httpRequest({
    method: 'POST',
    url: '/api/ObliqueRoof/GetCADComplexDetailInfo?projectId=' + data
  });
}

// 获取项目图纸
async function GetProjectList(id){
  return await httpRequest({
    method: 'POST',
    // url: '/api/ProjectRelation/GetList?ProjectId=' + id + '&t=' + new Date.getTime()
    url: '/api/ProjectRelation/GetList?ProjectId=' + id
  })
}
// 新增
function AddList(data) {
  return httpRequest({
    method: 'POST',
    url: '/api/Project/AddProject',
    data
  });
}

export { GetCADComplexDetailInfo, GetCADDetailInfo, GetProjectList, GetList, DeleteProject, GetProjectDetail, AddList };
