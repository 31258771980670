<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <div>
      <el-form ref="formRef" :model="formInline" inline>
        <div>
          <el-form-item>
            <el-input v-model="formInline.clientName" placeholder="客户名称" />
          </el-form-item>
          <el-form-item>
            <el-input v-model="formInline.clientTelephone" placeholder="客户电话" />
          </el-form-item>
          <el-form-item>
            <el-input v-model="formInline.belongToCompany" placeholder="所属公司" />
          </el-form-item>
          <el-form-item>
            <el-input v-model="formInline.serviceProviderTelephone" placeholder="联系人电话" />
          </el-form-item>
        </div>
        <div>
          <el-form-item style="marginTop:10px">
            <el-button type="primary" @click="onSubmit(formRef)" :loading="loading">查询</el-button>
            <el-button @click="resetForm(formRef)" :loading="loading">重置</el-button>
<!--            <el-button @click="addNew">新增</el-button>-->
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div style="height: calc(100% - 144px)">
      <el-table
        v-loading="loading"
        stripe
        :data="tableData"
        style="width: 100%; marginTop: 10px"
        @selection-change="handleSelectionChange"
        table-layout="auto"
        height="100%"
        :header-cell-style="{ background: '#eef1f6' }"
        fit
      >
        <el-table-column label="编号" prop="projectID"  />
        <el-table-column label="客户名称" prop="clientName"  />
        <el-table-column label="客户电话" prop="serviceProviderTelephone"  />
        <el-table-column label="项目地址" prop="projectAddress" />
        <el-table-column label="操作"  fixed="right">
          <template #default="scope">
            <!-- <el-button size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button> -->
            <el-button size="small" type="success" @click="details(scope.row)">详情</el-button>
<!--            <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="page"
          :page-size="limit"
          small="small"
          layout="total, prev, pager, next"
          :total="total"
          background
          @current-change="handlePageChange"
        />
      </div>
    </div>
    <el-dialog v-model="dialogVisible" :before-close="handleClose" align-center >
      <div class="app1">
        <!-- 项目数据 -->
        <ul>
          <li style="margin-bottom: 20px;">
            <span class="span1 bt">客户信息 </span>
          </li>
        </ul>
        <el-row :gutter="2">
          <el-col :span="12">
            <span class="span1">客户名称: </span
            ><span class="span2">{{ infoData.clientName }}</span>
          </el-col>
          <el-col :span="12">
            <span class="span1">手机号码: </span
            ><span class="span2">{{ infoData.serviceProviderTelephone }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="12">
            <span class="span1">项目地址: </span
            ><span class="span2">{{ infoData.projectAddress }}</span>
          </el-col>
          <el-col :span="12">
            <span class="span1">经纬度: </span
            ><span class="span2">{{ infoData.latitude }}{{ infoData.longitude }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="12">
            <span class="span1">风压等级: </span
            ><span class="span2">{{ infoData.snowPressure }}</span>
          </el-col>
          <el-col :span="12">
            <span class="span1">组件品牌: </span
            ><span class="span2">{{ infoData.comBrand }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="12">
            <span class="span1">组件型号: </span><span class="span2">{{ infoData.comModel }}</span>
          </el-col>
          <el-col :span="12">
            <span class="span1">逆变器品牌: </span
            ><span class="span2">{{ infoData.inverterBrandName }}</span>
          </el-col>
        </el-row>
        <el-divider />
        <!-- 房屋数据 -->
        <ul v-cloak>
          <template v-if="infoData.roofType === 1">
            <li style="margin-bottom: 20px;">
              <span class="span1 bt">屋顶类型: </span>
              <span class="span2">平屋顶</span>
            </li>
            <el-row :gutter="12">
              <el-col :span="8">
                <span class="span1">东西长度(mm): </span>
                <span class="span2">{{ flatRoofBasicModel.eastWestLength }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南北宽度(mm): </span>
                <span class="span2">{{ flatRoofBasicModel.northSouthWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">房屋高度(mm): </span>
                <span class="span2">{{ flatRoofBasicModel.houseHeight }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">房屋朝向: </span>
                <span class="span2">{{ flatRoofBasicModel.facingDirection }}</span>
              </el-col>
              <el-col :span="8" v-if="flatRoofBasicModel.facingDirection !== '正南'">
                <span class="span1">朝向度数(°): </span>
                <span class="span2">{{ flatRoofBasicModel.facingDegree }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">距并网店距离(mm): </span>
                <span class="span2">{{ flatRoofBasicModel.gridConnectionDistance }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">屋面类型: </span>
                <span class="span2">{{flatRoofBasicModel.roofingType}}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">屋面厚度(mm): </span
                ><span class="span2">{{ flatRoofBasicModel.roofSlabThickness }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">安装方式: </span>
                <span class="span2" >{{ flatRoofBasicModel.installationMethod }}</span>
              </el-col>
            </el-row>
          </template>
          <template v-if="infoData.roofType === 2">
            <li style="margin-bottom: 20px;">
              <span class="span1 bt">屋顶类型: </span><span class="span2">斜屋顶</span>
            </li>
            <el-row :gutter="12">
              <el-col :span="8">
                <span class="span1">东西长度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.eastWestLength }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南北宽度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.northSouthWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南坡宽度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSlopeWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南坡坡度(°): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSlope }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">北坡宽度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.northSlopeWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">北坡坡度(°): </span>
                <span class="span2">{{ obliqueRoofBasicModel.northSlope }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">屋檐高度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.eavesHigh }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">房屋高度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.houseHeight }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">房屋朝向: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.houseFacing === 1">正南</span>
                <span class="span2" v-if="obliqueRoofBasicModel.houseFacing === 2">南偏东</span>
                <span class="span2" v-if="obliqueRoofBasicModel.houseFacing === 3">南偏西</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.houseFacing !== 1">
                <span class="span1">偏向度数(°): </span>
                <span class="span2">{{ obliqueRoofBasicModel.facingDegree }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">距并网点距离(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.gridConnectionDistance }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">屋脊高度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.ridgeHigh }}</span>
              </el-col>
              <el-col :span="8" >
                <span class="span1">屋面类型: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 1">现浇板</span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 2">预制板</span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 3">木望板</span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 4">凛橼结构</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 1 || obliqueRoofBasicModel.roofingType === 2">
                <span class="span1">屋面厚度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.roofSlabThickness }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 3">
                <span class="span1">木檩直径(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.woodPurlinDiameter }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 3">
                <span class="span1">木檩间距(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.woodPurlinSpacing }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 3">
                <span class="span1">木望板厚度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.woodWatchboardThickness }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 4">
                <span class="span1">木檩条直径(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.purlinDiameter }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 4">
                <span class="span1">木檩条间距(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.purlinSpacing }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 4">
                <span class="span1">人字梁东西跨度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.herringboneBeamEastWest }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 4">
                <span class="span1">人字梁南北跨度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.herringboneBeamNorthSouth }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 4">
                <span class="span1">人字梁主梁直径(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.herringboneGirderDiameter }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南侧安装方式: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.sourthInstallType === 1">标准前支</span>
                <span class="span2" v-if="obliqueRoofBasicModel.sourthInstallType === 2">无水平杆前支</span>
                <span class="span2" v-if="obliqueRoofBasicModel.sourthInstallType === 3">前排挂钩</span>
                <span class="span2" v-if="obliqueRoofBasicModel.sourthInstallType === 4">全挂钩</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.sourthInstallType !== 4">
                <span class="span1">屋脊可探出距离(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.ridgeProtrude }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.sourthInstallType !== 4">
                <span class="span1">北侧安装方式: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.northInstallType === 1">标准背拉</span>
                <span class="span2" v-if="obliqueRoofBasicModel.northInstallType === 2">挂钩背拉</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.northInstallType === 1">
                <span class="span1">背拉方式: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.backPullMode === 1">全拉</span>
                <span class="span2" v-if="obliqueRoofBasicModel.backPullMode === 2">隔一拉一</span>
              </el-col>
            </el-row>
          </template>
          <template v-if="infoData.roofType === 4">
            <li style="margin-bottom: 20px;">
              <span class="span1 bt">屋顶类型: </span><span class="span2">外厦带平台屋顶</span>
            </li>
            <el-row :gutter="12">
              <el-col :span="8">
                <span class="span1">东西长度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.eastWestLength }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南北宽度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.northSouthWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南坡宽度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSlopeWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">南坡坡度(°): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSlope }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">北坡宽度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.northSlopeWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">北坡坡度(°): </span>
                <span class="span2">{{ obliqueRoofBasicModel.northSlope }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">屋檐高度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.eavesHigh }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">平台长度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSidePlatformLength }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">平台宽度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSidePlatformWidth }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">平台距西侧距离(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSidePlatformLocation }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">平台材质: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.plateMaterial === 1">现浇板</span>
                <span class="span2" v-if="obliqueRoofBasicModel.plateMaterial === 2">预制板</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">平台厚度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSidePlatformThickness }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">平台落差(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.southSidePlatformDrop }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">房屋高度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.houseHeight }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">房屋朝向: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.houseFacing === 1">正南</span>
                <span class="span2" v-if="obliqueRoofBasicModel.houseFacing === 2">南偏东</span>
                <span class="span2" v-if="obliqueRoofBasicModel.houseFacing === 3">南偏西</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.houseFacing !== 1">
                <span class="span1">偏向度数(°): </span>
                <span class="span2">{{ obliqueRoofBasicModel.facingDegree }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">距并网点距离(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.gridConnectionDistance }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">屋脊高度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.ridgeHigh }}</span>
              </el-col>
              <el-col :span="8">
                <span class="span1">屋面类型: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 1">现浇板</span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 2">预制板</span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 3">木望板</span>
                <span class="span2" v-if="obliqueRoofBasicModel.roofingType === 4">凛橼结构</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.roofingType === 1 || obliqueRoofBasicModel.roofingType === 2">
                <span class="span1">屋面厚度(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.roofSlabThickness }}</span>
              </el-col>
              <template v-if="obliqueRoofBasicModel.roofingType === 3">
                <el-col :span="8">
                  <span class="span1">木檩直径(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.woodPurlinDiameter }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="span1">木檩间距(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.woodPurlinSpacing }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="span1">木望板厚度(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.woodWatchboardThickness }}</span>
                </el-col>
              </template>
              <template v-if="obliqueRoofBasicModel.roofingType === 4">
                <el-col :span="8">
                  <span class="span1">木檩条直径(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.purlinDiameter }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="span1">木檩条间距(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.purlinSpacing }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="span1">人字梁东西跨度(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.herringboneBeamEastWest }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="span1">人字梁南北跨度(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.herringboneBeamNorthSouth }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="span1">人字梁主梁直径(mm): </span>
                  <span class="span2">{{ obliqueRoofBasicModel.herringboneGirderDiameter }}</span>
                </el-col>
              </template>
              <el-col :span="8" v-if="obliqueRoofBasicModel.sourthInstallType !== 4">
                <span class="span1">屋脊可探出距离(mm): </span>
                <span class="span2">{{ obliqueRoofBasicModel.ridgeProtrude }}</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.sourthInstallType !== 4">
                <span class="span1">北侧安装方式: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.northInstallType === 1">标准背拉</span>
                <span class="span2" v-if="obliqueRoofBasicModel.northInstallType === 2">挂钩背拉</span>
              </el-col>
              <el-col :span="8" v-if="obliqueRoofBasicModel.northInstallType === 1">
                <span class="span1">背拉方式: </span>
                <span class="span2" v-if="obliqueRoofBasicModel.backPullMode === 1">全拉</span>
                <span class="span2" v-if="obliqueRoofBasicModel.backPullMode === 2">隔一拉一</span>
              </el-col>
            </el-row>
          </template>
          <template v-if="infoData.roofType === 5">
            <el-row :gutter="12">
              <template v-for="(item, index) in complexDetailInfoModel" :key="index">
                <el-divider v-if="index === 1" />
                <template v-if="item.rooftype === 1">
                  <li style="margin-bottom: 20px;" v-if="index === 0">
                    <span class="span1 bt">屋顶一类型: </span><span class="span2">平屋顶</span>
                  </li>
                  <li style="margin-bottom: 20px;" v-if="index === 1">
                    <span class="span1 bt">屋顶二类型: </span><span class="span2">平屋顶</span>
                  </li>
                  <el-row :gutter="12">
                    <el-col :span="8">
                      <span class="span1">东西长度(mm): </span>
                      <span class="span2">{{ item.roofData.eastWestLength }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">南北宽度(mm): </span>
                      <span class="span2">{{ item.roofData.northSouthWidth }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">房屋高度(mm): </span>
                      <span class="span2">{{ item.roofData.houseHeight }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">房屋朝向: </span>
                      <span class="span2" v-if="item.roofData.facingDirection === 1">南偏东</span>
                      <span class="span2" v-if="item.roofData.facingDirection === 2">南偏西</span>
                      <span class="span2" v-if="item.roofData.facingDirection === 3">正南</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.facingDirection !== 3">
                      <span class="span1">朝向度数(°): </span>
                      <span class="span2">{{ item.roofData.facingDegree }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">距并网店距离(mm): </span>
                      <span class="span2">{{ item.roofData.gridConnectionDistance }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">屋面类型: </span>
                      <span class="span2" v-if="item.roofData.roofingType === 1">预制板</span>
                      <span class="span2" v-if="item.roofData.roofingType === 2">现浇板</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">屋面厚度(mm): </span>
                      <span class="span2">{{ item.roofData.roofSlabThickness }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">安装方式: </span>
                      <span class="span2" v-if="item.roofData.installationMethod === 1">混泥土基墩</span>
                      <span class="span2" v-if="item.roofData.installationMethod === 2">膨胀螺栓</span>
                    </el-col>
                  </el-row>
                </template>
                <template v-if="item.rooftype === 2">
                  <li style="margin-bottom: 20px;" v-if="index === 0">
                    <span class="span1 bt">屋顶一类型: </span><span class="span2">斜屋顶</span>
                  </li>
                  <li style="margin-bottom: 20px;" v-if="index === 1">
                    <span class="span1 bt">屋顶二类型: </span><span class="span2">斜屋顶</span>
                  </li>
                  <el-row :gutter="12">
                    <el-col :span="8">
                      <span class="span1">东西长度(mm): </span>
                      <span class="span2">{{ item.roofData.eastWestLength }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">南北宽度(mm): </span>
                      <span class="span2">{{ item.roofData.northSouthWidth }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">南坡宽度(mm): </span>
                      <span class="span2">{{ item.roofData.southSlopeWidth }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">南坡坡度(°): </span>
                      <span class="span2">{{ item.roofData.southSlope }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">北坡宽度(mm): </span>
                      <span class="span2">{{ item.roofData.northSlopeWidth }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">北坡坡度(°): </span>
                      <span class="span2">{{ item.roofData.northSlope }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">屋檐高度(mm): </span>
                      <span class="span2">{{ item.roofData.eavesHigh }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">房屋高度(mm): </span>
                      <span class="span2">{{ item.roofData.houseHeight }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">房屋朝向: </span>
                      <span class="span2" v-if="item.roofData.houseFacing === 1">正南</span>
                      <span class="span2" v-if="item.roofData.houseFacing === 2">南偏东</span>
                      <span class="span2" v-if="item.roofData.houseFacing === 3">南偏西</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.houseFacing !== 1">
                      <span class="span1">偏向度数(°): </span>
                      <span class="span2">{{ item.roofData.facingDegree }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">距并网点距离(mm): </span>
                      <span class="span2">{{ item.roofData.gridConnectionDistance }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">屋脊高度(mm): </span>
                      <span class="span2">{{ item.roofData.ridgeHigh }}</span>
                    </el-col>
                    <el-col :span="8" >
                      <span class="span1">屋面类型: </span>
                      <span class="span2" v-if="item.roofData.roofingType === 1">现浇板</span>
                      <span class="span2" v-if="item.roofData.roofingType === 2">预制板</span>
                      <span class="span2" v-if="item.roofData.roofingType === 3">木望板</span>
                      <span class="span2" v-if="item.roofData.roofingType === 4">凛橼结构</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 1 || item.roofData.roofingType === 2">
                      <span class="span1">屋面厚度(mm): </span>
                      <span class="span2">{{ item.roofData.roofSlabThickness }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 3">
                      <span class="span1">木檩直径(mm): </span>
                      <span class="span2">{{ item.roofData.woodPurlinDiameter }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 3">
                      <span class="span1">木檩间距(mm): </span>
                      <span class="span2">{{ item.roofData.woodPurlinSpacing }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 3">
                      <span class="span1">木望板厚度(mm): </span>
                      <span class="span2">{{ item.roofData.woodWatchboardThickness }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 4">
                      <span class="span1">木檩条直径(mm): </span>
                      <span class="span2">{{ item.roofData.purlinDiameter }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 4">
                      <span class="span1">木檩条间距(mm): </span>
                      <span class="span2">{{ item.roofData.purlinSpacing }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 4">
                      <span class="span1">人字梁东西跨度(mm): </span>
                      <span class="span2">{{ item.roofData.herringboneBeamEastWest }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 4">
                      <span class="span1">人字梁南北跨度(mm): </span>
                      <span class="span2">{{ item.roofData.herringboneBeamNorthSouth }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.roofingType === 4">
                      <span class="span1">人字梁主梁直径(mm): </span>
                      <span class="span2">{{ item.roofData.herringboneGirderDiameter }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="span1">南侧安装方式: </span>
                      <span class="span2" v-if="item.roofData.sourthInstallType === 1">标准前支</span>
                      <span class="span2" v-if="item.roofData.sourthInstallType === 2">无水平杆前支</span>
                      <span class="span2" v-if="item.roofData.sourthInstallType === 3">前排挂钩</span>
                      <span class="span2" v-if="item.roofData.sourthInstallType === 4">全挂钩</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.sourthInstallType !== 4">
                      <span class="span1">屋脊可探出距离(mm): </span>
                      <span class="span2">{{ item.roofData.ridgeProtrude }}</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.sourthInstallType !== 4">
                      <span class="span1">北侧安装方式: </span>
                      <span class="span2" v-if="item.roofData.northInstallType === 1">标准背拉</span>
                      <span class="span2" v-if="item.roofData.northInstallType === 2">挂钩背拉</span>
                    </el-col>
                    <el-col :span="8" v-if="item.roofData.northInstallType === 1">
                      <span class="span1">背拉方式: </span>
                      <span class="span2" v-if="item.roofData.backPullMode === 1">全拉</span>
                      <span class="span2" v-if="item.roofData.backPullMode === 2">隔一拉一</span>
                    </el-col>
                  </el-row>
                </template>
                <template v-if="item.rooftype === 4">
                  <li style="margin-bottom: 20px;" v-if="index === 0">
                    <span class="span1 bt">屋顶一类型: </span><span class="span2">外厦带平台屋顶</span>
                  </li>
                  <li style="margin-bottom: 20px;" v-if="index === 1">
                    <span class="span1 bt">屋顶二类型: </span><span class="span2">外厦带平台屋顶</span>
                  </li>
                  <el-row :gutter="12">
                      <el-col :span="8">
                        <span class="span1">东西长度(mm): </span>
                        <span class="span2">{{ item.roofData.eastWestLength }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">南北宽度(mm): </span>
                        <span class="span2">{{ item.roofData.northSouthWidth }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">南坡宽度(mm): </span>
                        <span class="span2">{{ item.roofData.southSlopeWidth }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">南坡坡度(°): </span>
                        <span class="span2">{{ item.roofData.southSlope }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">北坡宽度(mm): </span>
                        <span class="span2">{{ item.roofData.northSlopeWidth }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">北坡坡度(°): </span>
                        <span class="span2">{{ item.roofData.northSlope }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">屋檐高度(mm): </span>
                        <span class="span2">{{ item.roofData.eavesHigh }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">平台长度(mm): </span>
                        <span class="span2">{{ item.roofData.southSidePlatformLength }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">平台宽度(mm): </span>
                        <span class="span2">{{ item.roofData.southSidePlatformWidth }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">平台距西侧距离(mm): </span>
                        <span class="span2">{{ item.roofData.southSidePlatformLocation }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">平台材质: </span>
                        <span class="span2" v-if="item.roofData.plateMaterial === 1">现浇板</span>
                        <span class="span2" v-if="item.roofData.plateMaterial === 2">预制板</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">平台厚度(mm): </span>
                        <span class="span2">{{ item.roofData.southSidePlatformThickness }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">平台落差(mm): </span>
                        <span class="span2">{{ item.roofData.southSidePlatformDrop }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">房屋高度(mm): </span>
                        <span class="span2">{{ item.roofData.houseHeight }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">房屋朝向: </span>
                        <span class="span2" v-if="item.roofData.houseFacing === 1">正南</span>
                        <span class="span2" v-if="item.roofData.houseFacing === 2">南偏东</span>
                        <span class="span2" v-if="item.roofData.houseFacing === 3">南偏西</span>
                      </el-col>
                      <el-col :span="8" v-if="item.roofData.houseFacing !== 1">
                        <span class="span1">偏向度数(°): </span>
                        <span class="span2">{{ item.roofData.facingDegree }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">距并网点距离(mm): </span>
                        <span class="span2">{{ item.roofData.gridConnectionDistance }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">屋脊高度(mm): </span>
                        <span class="span2">{{ item.roofData.ridgeHigh }}</span>
                      </el-col>
                      <el-col :span="8">
                        <span class="span1">屋面类型: </span>
                        <span class="span2" v-if="item.roofData.roofingType === 1">现浇板</span>
                        <span class="span2" v-if="item.roofData.roofingType === 2">预制板</span>
                        <span class="span2" v-if="item.roofData.roofingType === 3">木望板</span>
                        <span class="span2" v-if="item.roofData.roofingType === 4">凛橼结构</span>
                      </el-col>
                      <el-col :span="8" v-if="item.roofData.roofingType === 1 || item.roofData.roofingType === 2">
                        <span class="span1">屋面厚度(mm): </span>
                        <span class="span2">{{ item.roofData.roofSlabThickness }}</span>
                      </el-col>
                      <template v-if="item.roofData.roofingType === 3">
                        <el-col :span="8">
                          <span class="span1">木檩直径(mm): </span>
                          <span class="span2">{{ item.roofData.woodPurlinDiameter }}</span>
                        </el-col>
                        <el-col :span="8">
                          <span class="span1">木檩间距(mm): </span>
                          <span class="span2">{{ item.roofData.woodPurlinSpacing }}</span>
                        </el-col>
                        <el-col :span="8">
                          <span class="span1">木望板厚度(mm): </span>
                          <span class="span2">{{ item.roofData.woodWatchboardThickness }}</span>
                        </el-col>
                      </template>
                      <template v-if="item.roofData.roofingType === 4">
                        <el-col :span="8">
                          <span class="span1">木檩条直径(mm): </span>
                          <span class="span2">{{ item.roofData.purlinDiameter }}</span>
                        </el-col>
                        <el-col :span="8">
                          <span class="span1">木檩条间距(mm): </span>
                          <span class="span2">{{ item.roofData.purlinSpacing }}</span>
                        </el-col>
                        <el-col :span="8">
                          <span class="span1">人字梁东西跨度(mm): </span>
                          <span class="span2">{{ item.roofData.herringboneBeamEastWest }}</span>
                        </el-col>
                        <el-col :span="8">
                          <span class="span1">人字梁南北跨度(mm): </span>
                          <span class="span2">{{ item.roofData.herringboneBeamNorthSouth }}</span>
                        </el-col>
                        <el-col :span="8">
                          <span class="span1">人字梁主梁直径(mm): </span>
                          <span class="span2">{{ item.roofData.herringboneGirderDiameter }}</span>
                        </el-col>
                      </template>
                      <el-col :span="8" v-if="item.roofData.sourthInstallType !== 4">
                        <span class="span1">屋脊可探出距离(mm): </span>
                        <span class="span2">{{ item.roofData.ridgeProtrude }}</span>
                      </el-col>
                      <el-col :span="8" v-if="item.roofData.sourthInstallType !== 4">
                        <span class="span1">北侧安装方式: </span>
                        <span class="span2" v-if="item.roofData.northInstallType === 1">标准背拉</span>
                        <span class="span2" v-if="item.roofData.northInstallType === 2">挂钩背拉</span>
                      </el-col>
                      <el-col :span="8" v-if="item.roofData.northInstallType === 1">
                        <span class="span1">背拉方式: </span>
                        <span class="span2" v-if="item.roofData.backPullMode === 1">全拉</span>
                        <span class="span2" v-if="item.roofData.backPullMode === 2">隔一拉一</span>
                      </el-col>
                    </el-row>
                </template>
              </template>
            </el-row>
          </template>
        </ul>
        <el-divider />
        <!-- 障碍物 -->
        <template v-if="infoData.roofType !== 5">
          <ul style="margin-left: 50px;">
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'上人通道'" v-if="key === 'roadObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'烟囱'" v-if="key==='chimneyObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">烟囱形状: </span>
                          <span class="span2" v-if="items.chimneyShapeType === 1">矩形烟囱</span>
                          <span class="span2" v-if="items.chimneyShapeType === 2">圆形烟囱</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">是</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">否</span>
                        </li>
                        <li>
                          <span class="span3">烟囱类型: </span>
                          <span class="span2" v-if="items.chimneyType === 1">有烟烟囱</span>
                          <span class="span2" v-if="items.chimneyType === 2">无烟烟囱</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 1">
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestLength }}</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 1">
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 2">
                          <span class="span3">烟囱直径（mm）: </span>
                          <span class="span2">{{ items.chimneyDiam }}</span>
                        </li>
                        <li>
                          <span class="span3">当前高度（mm）: </span>
                          <span class="span2">{{ items.currentHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可调节: </span>
                          <span class="span2" v-if="items.trueOrFalseAdjustable">可调节</span>
                          <span class="span2" v-if="!items.trueOrFalseAdjustable">不可调节</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">调节后高度（mm）: </span>
                          <span class="span2">{{ items.rearHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'热水器'" v-if="key==='heaterObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">南侧高度（mm）: </span>
                          <span class="span2">{{ items.sourthHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">北侧高度（mm）: </span>
                          <span class="span2">{{ items.northHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">是</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">否</span>
                        </li>
                        <li>
                          <span class="span3">是否可移动: </span>
                          <span class="span2" v-if="items.trueOrFalseAdjustable">可移动</span>
                          <span class="span2" v-if="!items.trueOrFalseAdjustable">不可移动</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">移动后X坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseRemove }}</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">移动后Y坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseMove }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'邻居房屋'" v-if="key==='neighborHouseObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'屋外障碍物'" v-if="key==='outSideObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">障碍物名称: </span>
                          <span class="span2">{{ items.name }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'女儿墙'" v-if="key==='parapetWallsObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">厚度（mm）: </span>
                          <span class="span2">{{ items.thickness }}</span>
                        </li>
                        <li>
                          <span class="span3">东侧高度（mm）: </span>
                          <span class="span2">{{ items.eastHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">西侧高度（mm）: </span>
                          <span class="span2">{{ items.westHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">南侧高度（mm）: </span>
                          <span class="span2">{{ items.sourthHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">北侧高度（mm）: </span>
                          <span class="span2">{{ items.northHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">是否有装饰物: </span>
                          <span class="span2" v-if="items.trueOrFalseDecorations">是</span>
                          <span class="span2" v-if="!items.trueOrFalseDecorations">否</span>
                        </li>
                        <li v-if="items.trueOrFalseDecorations">
                          <span class="span3">装饰物高度（mm）: </span>
                          <span class="span2">{{ items.decorationsHeight }}</span>
                        </li>
                        <li v-if="items.trueOrFalseDecorations">
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">装饰物可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">均可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove === 3">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'屋脊装饰物'" v-if="key==='ridgeOrnamentObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">装饰物高度（mm）: </span>
                          <span class="span2">{{ items.height }}</span>
                        </li>
                        <li>
                          <span class="span3">装饰物宽度（mm）: </span>
                          <span class="span2">{{ items.width }}</span>
                        </li>
                        <li>
                          <span class="span3">距西侧距离（mm）: </span>
                          <span class="span2">{{ items.westSideDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'屋面障碍物'" v-if="key==='roofingObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">障碍物名称: </span>
                          <span class="span2">{{ items.name }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                        <li>
                          <span class="span3">是否可移动: </span>
                          <span class="span2" v-if="items.trueOrFalseMove">可移动</span>
                          <span class="span2" v-if="!items.trueOrFalseMove">不可移动</span>
                        </li>
                        <li>
                          <span class="span3">移动后X坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseRemove }}</span>
                        </li>
                        <li>
                          <span class="span3">移动后Y坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseMove }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'阳光房'" v-if="key==='sunRoomObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>

                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'平台女儿墙'" v-if="key==='terraceParapetWallsObstacleModels' && item.length > 0">
                  平台女儿墙
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'树'" v-if="key==='treeObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">树干高度（mm）: </span>
                          <span class="span2">{{ items.trunkHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">树干直径（mm）: </span>
                          <span class="span2">{{ items.trunkDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">树冠高度（mm）: </span>
                          <span class="span2">{{ items.canopyHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">树冠直径（mm）: </span>
                          <span class="span2">{{ items.canopyDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">不可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 3">可修剪</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 4">不可修剪</span>
                        </li>
                        <li v-if="items.trueOrFalseRemove === 3">
                          <span class="span3">修剪后直径（mm）: </span>
                          <span class="span2">{{ items.backDiameter }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'电线'" v-if="key==='wireObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">突出高度（mm）: </span>
                          <span class="span2">{{ items.wireHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">电线线径（mm）:</span>
                          <span class="span2">{{ items.wireDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">电压等级: </span>
                          <span class="span2">{{ items.voltage }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                        <li>
                          <span class="span3">电线类型:</span>
                          <span class="span2" v-if="items.wireType === 1">屋外电线</span>
                          <span class="span2" v-if="items.wireType === 2">穿墙角电线</span>
                          <span class="span2" v-if="items.wireType === 3">横穿电线</span>
                        </li>
                        <li v-if="items.wireType !== 3">
                          <span class="span3">电线位置:</span>
                          <span class="span2" v-if="items.wireLocation === 1">东侧</span>
                          <span class="span2" v-if="items.wireLocation === 2">西侧</span>
                          <span class="span2" v-if="items.wireLocation === 3">南侧</span>
                          <span class="span2" v-if="items.wireLocation === 4">东南角</span>
                          <span class="span2" v-if="items.wireLocation === 5">东北角</span>
                          <span class="span2" v-if="items.wireLocation === 6">西南角</span>
                          <span class="span2" v-if="items.wireLocation === 7">西北角</span>
                        </li>
                        <li v-if="items.wireType === 1">
                          <span class="span3" v-if="items.wireLocation === 1">距东南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 1">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 2 || items.wireLocation === 3">距西南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 2 || items.wireLocation === 3">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 1">
                          <span class="span3" v-if="items.wireLocation === 1">距东北角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 1">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 2">距西北角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 2">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 3">距东南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 3">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 2">
                          <span class="span3" v-if="items.wireLocation === 4">距东南角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 4">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 5">距东北角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 5">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 6">距西南角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 6">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 7">距西北角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 7">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 2">
                          <span class="span3" v-if="items.wireLocation === 4">距东南角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 4">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 5">距东北角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 5">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 6">距西南角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 6">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 7">距西北角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 7">{{ items.sourthNorthLength }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll" :key="key">
                <el-collapse-item :title="'电线杆'" v-if="key==='wirePoleObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">直径（mm）: </span>
                          <span class="span2">{{ items.diameter }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.height }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
          </ul>
        </template>
        <template v-if="infoData.roofType === 5">
          <ul>
            <li style="margin-bottom: 20px;">
              <span class="span1 bt">屋顶一障碍物: </span>
            </li>
          </ul>
          <ul style="margin-left: 50px;">
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'上人通道'" v-if="key === 'roadObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'烟囱'" v-if="key==='chimneyObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">烟囱形状: </span>
                          <span class="span2" v-if="items.chimneyShapeType === 1">矩形烟囱</span>
                          <span class="span2" v-if="items.chimneyShapeType === 2">圆形烟囱</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">是</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">否</span>
                        </li>
                        <li>
                          <span class="span3">烟囱类型: </span>
                          <span class="span2" v-if="items.chimneyType === 1">有烟烟囱</span>
                          <span class="span2" v-if="items.chimneyType === 2">无烟烟囱</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 1">
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestLength }}</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 1">
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 2">
                          <span class="span3">烟囱直径（mm）: </span>
                          <span class="span2">{{ items.chimneyDiam }}</span>
                        </li>
                        <li>
                          <span class="span3">当前高度（mm）: </span>
                          <span class="span2">{{ items.currentHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可调节: </span>
                          <span class="span2" v-if="items.trueOrFalseAdjustable">可调节</span>
                          <span class="span2" v-if="!items.trueOrFalseAdjustable">不可调节</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">调节后高度（mm）: </span>
                          <span class="span2">{{ items.rearHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'热水器'" v-if="key==='heaterObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">南侧高度（mm）: </span>
                          <span class="span2">{{ items.sourthHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">北侧高度（mm）: </span>
                          <span class="span2">{{ items.northHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">是</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">否</span>
                        </li>
                        <li>
                          <span class="span3">是否可移动: </span>
                          <span class="span2" v-if="items.trueOrFalseAdjustable">可移动</span>
                          <span class="span2" v-if="!items.trueOrFalseAdjustable">不可移动</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">移动后X坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseRemove }}</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">移动后Y坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseMove }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'邻居房屋'" v-if="key==='neighborHouseObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'屋外障碍物'" v-if="key==='outSideObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">障碍物名称: </span>
                          <span class="span2">{{ items.name }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>

              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'女儿墙'" v-if="key==='parapetWallsObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">厚度（mm）: </span>
                          <span class="span2">{{ items.thickness }}</span>
                        </li>
                        <li>
                          <span class="span3">东侧高度（mm）: </span>
                          <span class="span2">{{ items.eastHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">西侧高度（mm）: </span>
                          <span class="span2">{{ items.westHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">南侧高度（mm）: </span>
                          <span class="span2">{{ items.sourthHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">北侧高度（mm）: </span>
                          <span class="span2">{{ items.northHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">是否有装饰物: </span>
                          <span class="span2" v-if="items.trueOrFalseDecorations">是</span>
                          <span class="span2" v-if="!items.trueOrFalseDecorations">否</span>
                        </li>
                        <li v-if="items.trueOrFalseDecorations">
                          <span class="span3">装饰物高度（mm）: </span>
                          <span class="span2">{{ items.decorationsHeight }}</span>
                        </li>
                        <li v-if="items.trueOrFalseDecorations">
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">装饰物可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">均可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove === 3">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'屋脊装饰物'" v-if="key==='ridgeOrnamentObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">装饰物高度（mm）: </span>
                          <span class="span2">{{ items.height }}</span>
                        </li>
                        <li>
                          <span class="span3">装饰物宽度（mm）: </span>
                          <span class="span2">{{ items.width }}</span>
                        </li>
                        <li>
                          <span class="span3">距西侧距离（mm）: </span>
                          <span class="span2">{{ items.westSideDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'屋面障碍物'" v-if="key==='roofingObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">障碍物名称: </span>
                          <span class="span2">{{ items.name }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                        <li>
                          <span class="span3">是否可移动: </span>
                          <span class="span2" v-if="items.trueOrFalseMove">可移动</span>
                          <span class="span2" v-if="!items.trueOrFalseMove">不可移动</span>
                        </li>
                        <li>
                          <span class="span3">移动后X坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseRemove }}</span>
                        </li>
                        <li>
                          <span class="span3">移动后Y坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseMove }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'阳光房'" v-if="key==='sunRoomObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>

                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'平台女儿墙'" v-if="key==='terraceParapetWallsObstacleModels' && item.length > 0">
                  平台女儿墙
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'树'" v-if="key==='treeObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">树干高度（mm）: </span>
                          <span class="span2">{{ items.trunkHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">树干直径（mm）: </span>
                          <span class="span2">{{ items.trunkDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">树冠高度（mm）: </span>
                          <span class="span2">{{ items.canopyHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">树冠直径（mm）: </span>
                          <span class="span2">{{ items.canopyDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">不可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 3">可修剪</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 4">不可修剪</span>
                        </li>
                        <li v-if="items.trueOrFalseRemove === 3">
                          <span class="span3">修剪后直径（mm）: </span>
                          <span class="span2">{{ items.backDiameter }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'电线'" v-if="key==='wireObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">突出高度（mm）: </span>
                          <span class="span2">{{ items.wireHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">电线线径（mm）:</span>
                          <span class="span2">{{ items.wireDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">电压等级: </span>
                          <span class="span2">{{ items.voltage }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                        <li>
                          <span class="span3">电线类型:</span>
                          <span class="span2" v-if="items.wireType === 1">屋外电线</span>
                          <span class="span2" v-if="items.wireType === 2">穿墙角电线</span>
                          <span class="span2" v-if="items.wireType === 3">横穿电线</span>
                        </li>
                        <li v-if="items.wireType !== 3">
                          <span class="span3">电线位置:</span>
                          <span class="span2" v-if="items.wireLocation === 1">东侧</span>
                          <span class="span2" v-if="items.wireLocation === 2">西侧</span>
                          <span class="span2" v-if="items.wireLocation === 3">南侧</span>
                          <span class="span2" v-if="items.wireLocation === 4">东南角</span>
                          <span class="span2" v-if="items.wireLocation === 5">东北角</span>
                          <span class="span2" v-if="items.wireLocation === 6">西南角</span>
                          <span class="span2" v-if="items.wireLocation === 7">西北角</span>
                        </li>
                        <li v-if="items.wireType === 1">
                          <span class="span3" v-if="items.wireLocation === 1">距东南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 1">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 2 || items.wireLocation === 3">距西南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 2 || items.wireLocation === 3">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 1">
                          <span class="span3" v-if="items.wireLocation === 1">距东北角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 1">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 2">距西北角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 2">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 3">距东南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 3">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 2">
                          <span class="span3" v-if="items.wireLocation === 4">距东南角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 4">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 5">距东北角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 5">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 6">距西南角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 6">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 7">距西北角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 7">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 2">
                          <span class="span3" v-if="items.wireLocation === 4">距东南角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 4">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 5">距东北角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 5">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 6">距西南角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 6">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 7">距西北角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 7">{{ items.sourthNorthLength }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll1" :key="key">
                <el-collapse-item :title="'电线杆'" v-if="key==='wirePoleObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">直径（mm）: </span>
                          <span class="span2">{{ items.diameter }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.height }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
          </ul>
          <ul>
            <li style="margin-bottom: 20px;">
              <span class="span1 bt">屋顶二障碍物: </span>
            </li>
          </ul>
          <ul style="margin-left: 50px;">
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'上人通道'" v-if="key === 'roadObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'烟囱'" v-if="key==='chimneyObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">烟囱形状: </span>
                          <span class="span2" v-if="items.chimneyShapeType === 1">矩形烟囱</span>
                          <span class="span2" v-if="items.chimneyShapeType === 2">圆形烟囱</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">是</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">否</span>
                        </li>
                        <li>
                          <span class="span3">烟囱类型: </span>
                          <span class="span2" v-if="items.chimneyType === 1">有烟烟囱</span>
                          <span class="span2" v-if="items.chimneyType === 2">无烟烟囱</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 1">
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestLength }}</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 1">
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.chimneyShapeType === 2">
                          <span class="span3">烟囱直径（mm）: </span>
                          <span class="span2">{{ items.chimneyDiam }}</span>
                        </li>
                        <li>
                          <span class="span3">当前高度（mm）: </span>
                          <span class="span2">{{ items.currentHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可调节: </span>
                          <span class="span2" v-if="items.trueOrFalseAdjustable">可调节</span>
                          <span class="span2" v-if="!items.trueOrFalseAdjustable">不可调节</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">调节后高度（mm）: </span>
                          <span class="span2">{{ items.rearHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'热水器'" v-if="key==='heaterObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">南侧高度（mm）: </span>
                          <span class="span2">{{ items.sourthHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">北侧高度（mm）: </span>
                          <span class="span2">{{ items.northHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">是</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">否</span>
                        </li>
                        <li>
                          <span class="span3">是否可移动: </span>
                          <span class="span2" v-if="items.trueOrFalseAdjustable">可移动</span>
                          <span class="span2" v-if="!items.trueOrFalseAdjustable">不可移动</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">移动后X坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseRemove }}</span>
                        </li>
                        <li v-if="items.trueOrFalseAdjustable">
                          <span class="span3">移动后Y坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseMove }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'邻居房屋'" v-if="key==='neighborHouseObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'屋外障碍物'" v-if="key==='outSideObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">障碍物名称: </span>
                          <span class="span2">{{ items.name }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>

              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'女儿墙'" v-if="key==='parapetWallsObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">厚度（mm）: </span>
                          <span class="span2">{{ items.thickness }}</span>
                        </li>
                        <li>
                          <span class="span3">东侧高度（mm）: </span>
                          <span class="span2">{{ items.eastHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">西侧高度（mm）: </span>
                          <span class="span2">{{ items.westHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">南侧高度（mm）: </span>
                          <span class="span2">{{ items.sourthHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">北侧高度（mm）: </span>
                          <span class="span2">{{ items.northHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">是否有装饰物: </span>
                          <span class="span2" v-if="items.trueOrFalseDecorations">是</span>
                          <span class="span2" v-if="!items.trueOrFalseDecorations">否</span>
                        </li>
                        <li v-if="items.trueOrFalseDecorations">
                          <span class="span3">装饰物高度（mm）: </span>
                          <span class="span2">{{ items.decorationsHeight }}</span>
                        </li>
                        <li v-if="items.trueOrFalseDecorations">
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">装饰物可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">均可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove === 3">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'屋脊装饰物'" v-if="key==='ridgeOrnamentObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">装饰物高度（mm）: </span>
                          <span class="span2">{{ items.height }}</span>
                        </li>
                        <li>
                          <span class="span3">装饰物宽度（mm）: </span>
                          <span class="span2">{{ items.width }}</span>
                        </li>
                        <li>
                          <span class="span3">距西侧距离（mm）: </span>
                          <span class="span2">{{ items.westSideDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'屋面障碍物'" v-if="key==='roofingObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">障碍物名称: </span>
                          <span class="span2">{{ items.name }}</span>
                        </li>
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                        <li>
                          <span class="span3">是否可移动: </span>
                          <span class="span2" v-if="items.trueOrFalseMove">可移动</span>
                          <span class="span2" v-if="!items.trueOrFalseMove">不可移动</span>
                        </li>
                        <li>
                          <span class="span3">移动后X坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseRemove }}</span>
                        </li>
                        <li>
                          <span class="span3">移动后Y坐标（mm）: </span>
                          <span class="span2">{{ items.rearTrueOrFalseMove }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'阳光房'" v-if="key==='sunRoomObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">东西长度（mm）: </span>
                          <span class="span2">{{ items.eastWestDistance }}</span>
                        </li>

                        <li>
                          <span class="span3">南北宽度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.sourthNorthDistance }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'平台女儿墙'" v-if="key==='terraceParapetWallsObstacleModels' && item.length > 0">
                  平台女儿墙
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'树'" v-if="key==='treeObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">树干高度（mm）: </span>
                          <span class="span2">{{ items.trunkHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">树干直径（mm）: </span>
                          <span class="span2">{{ items.trunkDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">树冠高度（mm）: </span>
                          <span class="span2">{{ items.canopyHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">树冠直径（mm）: </span>
                          <span class="span2">{{ items.canopyDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 1">可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 2">不可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 3">可修剪</span>
                          <span class="span2" v-if="items.trueOrFalseRemove === 4">不可修剪</span>
                        </li>
                        <li v-if="items.trueOrFalseRemove === 3">
                          <span class="span3">修剪后直径（mm）: </span>
                          <span class="span2">{{ items.backDiameter }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'电线'" v-if="key==='wireObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">突出高度（mm）: </span>
                          <span class="span2">{{ items.wireHeight }}</span>
                        </li>
                        <li>
                          <span class="span3">电线线径（mm）:</span>
                          <span class="span2">{{ items.wireDiameter }}</span>
                        </li>
                        <li>
                          <span class="span3">电压等级: </span>
                          <span class="span2">{{ items.voltage }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除</span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                        <li>
                          <span class="span3">电线类型:</span>
                          <span class="span2" v-if="items.wireType === 1">屋外电线</span>
                          <span class="span2" v-if="items.wireType === 2">穿墙角电线</span>
                          <span class="span2" v-if="items.wireType === 3">横穿电线</span>
                        </li>
                        <li v-if="items.wireType !== 3">
                          <span class="span3">电线位置:</span>
                          <span class="span2" v-if="items.wireLocation === 1">东侧</span>
                          <span class="span2" v-if="items.wireLocation === 2">西侧</span>
                          <span class="span2" v-if="items.wireLocation === 3">南侧</span>
                          <span class="span2" v-if="items.wireLocation === 4">东南角</span>
                          <span class="span2" v-if="items.wireLocation === 5">东北角</span>
                          <span class="span2" v-if="items.wireLocation === 6">西南角</span>
                          <span class="span2" v-if="items.wireLocation === 7">西北角</span>
                        </li>
                        <li v-if="items.wireType === 1">
                          <span class="span3" v-if="items.wireLocation === 1">距东南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 1">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 2 || items.wireLocation === 3">距西南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 2 || items.wireLocation === 3">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 1">
                          <span class="span3" v-if="items.wireLocation === 1">距东北角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 1">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 2">距西北角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 2">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 3">距东南角距离（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 3">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 2">
                          <span class="span3" v-if="items.wireLocation === 4">距东南角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 4">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 5">距东北角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 5">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 6">距西南角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 6">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 7">距西北角x（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 7">{{ items.sourthNorthLength }}</span>
                        </li>
                        <li v-if="items.wireType === 2">
                          <span class="span3" v-if="items.wireLocation === 4">距东南角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 4">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 5">距东北角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 5">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 6">距西南角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 6">{{ items.sourthNorthLength }}</span>
                          <span class="span3" v-if="items.wireLocation === 7">距西北角y（mm）: </span>
                          <span class="span2" v-if="items.wireLocation === 7">{{ items.sourthNorthLength }}</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
            <el-collapse>
              <template v-for="(item, key) in dataAll2" :key="key">
                <el-collapse-item :title="'电线杆'" v-if="key==='wirePoleObstacleModels' && item.length > 0">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(items, key) in item" :key="key">
                      <el-card shadow="hover">
                        <li>
                          <span class="span3">直径（mm）: </span>
                          <span class="span2">{{ items.diameter }}</span>
                        </li>
                        <li>
                          <span class="span3">本身高度（mm）: </span>
                          <span class="span2">{{ items.height }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角X坐标（mm）: </span>
                          <span class="span2">{{ items.xCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">距西南角Y坐标（mm）: </span>
                          <span class="span2">{{ items.yCoordinates }}</span>
                        </li>
                        <li>
                          <span class="span3">是否可移除: </span>
                          <span class="span2" v-if="items.trueOrFalseRemove">可移除</span>
                          <span class="span2" v-if="!items.trueOrFalseRemove">不可移除</span>
                        </li>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </template>
            </el-collapse>
          </ul>
        </template>
        <el-divider />
        <!-- 图纸 -->
        <el-row :gutter="12">
          <el-col :span="8">
            <ul v-if="infoData.roofType !== 5">
              <li>
                <span class="span4">排布图: </span>
                <span class="span2">
                  <el-button type="primary" @click="paibu">DWG下载</el-button>
                </span>
              </li>
              <li>
                <span class="span4">侧视图: </span>
                <span class="span2">
              <el-button type="primary" @click="ceshi">DWG下载</el-button>
              </span>
              </li>
            </ul>
          </el-col>
          <el-col :span="8">
            <ul v-if="infoData.roofType !== 5">
              <li v-if="roofT1 === 1">
                <span class="span4">后视图: </span
                ><span class="span2"
                  ><el-button type="primary" @click="houshi">DWG下载</el-button
                ></span>
              </li>
              <li>
            <span class="span4">接线图: </span
            ><span class="span2"
              ><el-button type="primary" @click="jiexian">DWG下载</el-button
              ></span>
              </li>
              <!-- <li>
                <span class="span1">BOM: </span
                ><span class="span2"
                  ><el-button type="primary" @click="bom">BOM下载</el-button
                  ></span>
              </li> -->
            </ul>
          </el-col>
        </el-row>

        <el-row :gutter="12">
          <el-col :span="8">
            <ul v-if="infoData.roofType === 5">
              <li>
                <span class="span4">屋顶一排布图: </span>
                <span class="span2">
                  <el-button type="primary" @click="paibu">DWG下载</el-button>
                </span>
              </li>
              <li>
                <span class="span4">屋顶一侧视图: </span>
                <span class="span2">
                <el-button type="primary" @click="ceshi">DWG下载</el-button>
              </span>
              </li>
              <li v-if="roofT1 === 1">
                <span class="span4">屋顶一后视图: </span>
                <span class="span2">
                  <el-button type="primary" @click="houshi">DWG下载</el-button>
                </span>
              </li>
              <li>
                <span class="span4">屋顶一接线图: </span>
                <span class="span2">
                  <el-button type="primary" @click="jiexian">DWG下载</el-button>
                </span>
              </li>
            </ul>
          </el-col>
          <el-col :span="8">
            <ul v-if="infoData.roofType === 5">
              <li>
            <span class="span4">屋顶二排布图: </span
            ><span class="span2"
              ><el-button type="primary" @click="paibu2">DWG下载</el-button
              ></span>
              </li>
              <li>
                <span class="span4">屋顶二侧视图: </span>
                <span class="span2">
              <el-button type="primary" @click="ceshi2">DWG下载</el-button>
              </span>
              </li>
              <li v-if="roofT2 === 1">
            <span class="span4">屋顶二后视图: </span>
                <span class="span2"
              ><el-button type="primary" @click="houshi2">DWG下载</el-button
              ></span>
              </li>
              <li>
            <span class="span4">屋顶二接线图: </span
            ><span class="span2"
              ><el-button type="primary" @click="jiexian2">DWG下载</el-button
              ></span>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose()">关闭</el-button>
          <!-- <el-button type="primary" @click="dialogVisible = false">
          Confirm
        </el-button> -->
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="addVisible" :before-close="addClose">
      <el-form :model="addForm" :rules="rules" ref="formRef" label-width="100px" class="goodForm" v-loading="loading">
      <!-- <el-form-item label="逆变器品牌" prop="brandName">
        <el-select v-model="addForm.brandName" placeholder="请选择逆变器品牌">
          <el-option label="全部" :value="0" />
          <el-option v-for="option in BRAND_NAME_TYPES" :key="option.value" :label="option.label"
            :value="option.value" />
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="项目ID" prop="projectID">
        <el-input v-model.number="addForm.projectID" placeholder="请输入项目id" />
      </el-form-item> -->
      <el-form-item label="客户姓名" prop="clientName">
        <el-input v-model="addForm.clientName" placeholder="请输入客户姓名" />
      </el-form-item>
      <el-form-item label="客户电话" prop="clientTelephone">
        <el-input v-model="addForm.clientTelephone" placeholder="请输入客户电话" />
      </el-form-item>
      <el-form-item label="经度" prop="latitude">
        <el-input v-model="addForm.latitude" placeholder="请输入经度" />
      </el-form-item>
      <el-form-item label="纬度" prop="longitude">
        <el-input v-model="addForm.longitude" placeholder="请输入纬度" />
      </el-form-item>
      <el-form-item label="所属公司" prop="belongToCompany">
          <el-input v-model="addForm.belongToCompany" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="逆变器品牌" prop="inverterBrandName">
          <el-input v-model="addForm.inverterBrandName" placeholder="请输入逆变器品牌" />
      </el-form-item>
       <el-form-item label="地区" prop="region">
          <el-select v-model="addForm.region" placeholder="请选择项目地区">
            <el-option v-for="option in WIND_AREA" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
       <el-form-item label="详细地址" prop="projectAddress">
         <el-input v-model="addForm.projectAddress" placeholder="请输入详细地址" />
        </el-form-item>
      <el-form-item label="组件品牌" prop="comBrand">
          <el-select v-model="addForm.comBrand" placeholder="请选择组件品牌">
            <el-option v-for="option in COM_BRAND" :key="option.value" :label="option.label" :value="option.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组件型号" prop="comModel">
          <el-select v-model="addForm.comModel" placeholder="请选择组件型号">
            <el-option v-for="option in COM_MODAL_TYPE" :key="option.value" :label="option.label" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="屋顶类型" prop="roofType">
          <el-select v-model="addForm.roofType" placeholder="请选择屋顶类型">
            <el-option v-for="option in ROOF_TYPES" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="复合1" v-if="addForm.roofType == 5" prop="roofSubType1">
          <el-select v-model="addForm.roofSubType1" placeholder="请选择屋顶类型">
            <el-option v-for="option in ROOF_SUBTYPE" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="复合2" v-if="addForm.roofType == 5" prop="roofSubType2">
          <el-select v-model="addForm.roofSubType2" placeholder="请选择屋顶类型">
            <el-option v-for="option in ROOF_SUBTYPE" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="风压等级" prop="snowPressure">
          <el-select v-model="addForm.snowPressure" placeholder="请选择">
            <el-option v-for="option in windPressure" :key="option.value" :label="option.label"
                       :value="option.value" />
          </el-select>
        </el-form-item>
       <el-form-item style="marginTop:10px">
            <el-button @click="add(addForm)">确定</el-button>
        </el-form-item>
    </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue-demi';
import {
  GetList,
  DeleteProject,
  GetProjectDetail,
  AddList,
  GetProjectList,
  GetCADDetailInfo,
  GetCADComplexDetailInfo
} from '@/request/api.js';
import { ROOF_SUBTYPE, N_ATLAS, HOLDER_TYPE, INSTALLATION, PROJECT_TYPE, ROOF_TYPES, COM_MODAL_TYPE, RAISE_TYPES, PULL_BACK_TYPES, INSTALL_METHOD_TYPES, WIND_PRESSURE_LEVEL_TYPES, NORTH_SOUTH_TYPE, COM_BRAND, WIND_AREA} from '../Atlas/const/index';
import { ElMessage } from 'element-plus';
// import { toRaw } from "vue";
export default {
  components: {
    // Look
  },
  setup() {
    const dialogRef = ref(null);
    const data = reactive({
      roofT1: 0,
      roofT2: 0,
      addVisible:false,
      show: true,
      page: 1,
      limit: 20,
      total: null,
      tableData: [],
      formInline: {
        clientName: '', //客户名称
        clientTelephone: '', //客户电话
        belongToCompany: '', //所属公司
        serviceProviderTelephone: '' //联系人电话
      },
      dialogVisible: false,
      infoData: {},
      flatRoofBasicModel: {}, //平屋顶数据
      obliqueRoofBasicModel: {}, //斜屋顶数据
      complexDetailInfoModel: [], //复合屋顶数据
      dataAll:{}, // 单屋顶障碍物
      dataAll1: {},
      dataAll2: {},
      dataAllOf: [[], []], // 双屋顶障碍物
      zhangaiwu:{
        "障碍物长度":'500mm'
      },
      // pluginUrl:'https://192.168.0.119:9169/CADFile/temp/',
      // pluginUrl:'https://chuiyang.solarqt.com:9169/CADFile/temp/',
      // pluginUrl:'http://112.35.62.183:9168/CADFile/temp/',
      // pluginUrl:'http://112.35.62.183:9888/CADFile/temp/',
      // pluginUrl:'https://design.pvbuyer.cn/CADFile/temp/',
      pluginUrl:'https://design.pvbuyer.cn/api/File/Download?fileId=',
      paibuImg: '',
      ceshiImg:'',
      houshiImg:'',
      jiexianImg:'',
      paibuImg2: '',
      ceshiImg2:'',
      houshiImg2:'',
      jiexianImg2:'',
      bomImg:[],
      drawing:[],
      addForm:{
        projectID:0,//项目ID
        comBrand: '', //组件品牌
        comModel: '', //组件型号
        clientName: '',//客户姓名
        clientTelephone: '',//客户电话
        projectNumber: '',//项目编号
        belongToCompany: '',//所属公司
        latitude: '',//经度
        longitude: '',//纬度
        comSize: '', //
        inverterBrandName: '', //逆变器品牌
        projectAddress:'', //项目地址
        region:'',//项目地区
        roofType: '', //屋顶类型
        roofSubType1: null,//屋顶类型
        roofSubType2: null,//屋顶类型
        snowPressure: null, //风压等级
      },
      windPressure:[
        {
          label:'低风压',
          value:1
        },
        {
          label:'中风压',
          value:2
        },
        {
          label:'高风压',
          value:3
        },
      ]
    });
    const houshiShow1 = ref(false);
    const houshiShow2 = ref(false);
    onMounted(() => {
      methodsMap.getProjectList();
    });
    const methodsMap = {
      // 新增
      add(value){
        AddList(value).then(res => {
          console.log(res, '55555555555555555')
          if(res.success){
             data.addVisible = false
            //  data.addForm = {},
             ElMessage({
              message: res.message,
              type: 'success'
            });
            methodsMap.getProjectList();
          }else{
            ElMessage({
              message: res.message,
              type: 'error'
            });
          }
        })
      },
      addNew(){
        data.addVisible = true
      },
      handleClose() {
        data.paibuImg = ''
        data.ceshiImg = ''
        data.houshiImg = ''
        data.jiexianImg = ''
        data.paibuImg2 = ''
        data.ceshiImg2 = ''
        data.houshiImg2 = ''
        data.jiexianImg2 = ''
        data.dialogVisible = false,
        data.dataAll = {}
      },
      addClose(){
        // data.paibuImg = ''
        // data.ceshiImg = ''
        // data.houshiImg = ''
        // data.jiexianImg = ''
        data.addVisible = false
      },
      closePage() {
        data.show = false;
      },
      // 获取项目列表
      getProjectList() {
        let val = {
          limit: data.limit,
          page: data.page,
          params: {
            clientName: data.formInline.clientName,
            clientTelephone: data.formInline.clientTelephone,
            belongToCompany: data.formInline.belongToCompany,
            serviceProviderTelephone: data.formInline.serviceProviderTelephone
          }
        };
        GetList(val).then(res => {
          data.tableData = res.data.items;
          data.total = res.data.total;
          data.page = res.data.pageIndex;
          data.limit = res.data.pageSize;
        });
      },
      // 查询
      onSubmit() {
        methodsMap.getProjectList();
      },
      // 重置
      resetForm() {
        data.formInline = {
          clientName: '', //客户名称
          clientTelephone: '', //客户电话
          belongToCompany: '', //所属公司
          serviceProviderTelephone: '' //联系人电话
        };
        methodsMap.getProjectList();
      },
      handleSelectionChange() {},
      // 编辑
      handleEdit() {},
      // 删除
      handleDelete(val) {
        DeleteProject(val.projectID).then(res => {
          if (res.success) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            methodsMap.getProjectList();
            // location.reload()
          } else {
            ElMessage({
              message: res.message,
              type: 'error'
            });
          }
        });
      },
      sunFun() {
        data.xiangqing = false;
      },
      // 详情
      async details(val) {
        data.infoData = val;
        if (data.infoData.snowPressure === 3) {
          data.infoData.snowPressure = '高风压地区';
        } else if (data.infoData.snowPressure === 2) {
          data.infoData.snowPressure = '中风压地区';
        } else {
          data.infoData.snowPressure = '低风压地区';
        }
        console.log(data.infoData, 'dhh')
        console.log(data.infoData.roofType, 'rooftype')
        if (data.infoData.roofType === 1) {
          data.roofT1 = 1
          console.log('平')
          GetProjectDetail(data.infoData.projectID).then(res => {
            for (const key in res.data) {
              if (key !== 'flatRoofBasicModel' && key !== 'projectModel' && key !== 'projectFileRelationModels') {
                data.dataAll[key] = res.data[key]
              }
            }
            data.drawing = res.data.projectFileRelationModels
            if (res.data.flatRoofBasicModel !== null) {
              data.flatRoofBasicModel = res.data.flatRoofBasicModel;
              if (data.flatRoofBasicModel.roofingType === 1) {
                data.flatRoofBasicModel.roofingType = '现浇板';
              } else if (data.flatRoofBasicModel.roofingType === 2) {
                data.flatRoofBasicModel.roofingType = '预制板';
              } else if (data.flatRoofBasicModel.roofingType === 3) {
                data.flatRoofBasicModel.roofingType = '木望板';
              } else {
                data.flatRoofBasicModel.roofingType = '凛橼结构';
              }
              if (data.flatRoofBasicModel.installationMethod === 1) {
                data.flatRoofBasicModel.installationMethod = '混泥土基墩';
              } else if (data.flatRoofBasicModel.installationMethod === 2) {
                data.flatRoofBasicModel.installationMethod = '膨胀螺栓';
              } else {
                data.flatRoofBasicModel.installationMethod = '混凝土+膨胀螺栓';
              }
              if (data.flatRoofBasicModel.facingDirection === 1) {
                data.flatRoofBasicModel.facingDirection = '南偏东';
              } else if (data.flatRoofBasicModel.facingDirection === 2) {
                data.flatRoofBasicModel.facingDirection = '南偏西';
              } else if (data.flatRoofBasicModel.facingDirection === 3) {
                data.flatRoofBasicModel.facingDirection = '正南';
              }
            }
          });
        } else if (data.infoData.roofType === 2 || data.infoData.roofType === 3 || data.infoData.roofType === 4) {
          data.roofT1 = data.infoData.roofType
          console.log('斜', val.projectID)
          let { data: res } = await GetCADDetailInfo(val.projectID)
          for (const key in res) {
            if (key !== 'obliqueRoofBasicModel' && key !== 'projectModel' && key !== 'projectFileRelationModels') {
              data.dataAll[key] = res[key]
            }
          }
          if (res) {
            data.obliqueRoofBasicModel = res.obliqueRoofBasicModel
          }
        } else if (data.infoData.roofType === 5) {
          console.log('复合')
          let { data: res } = await GetCADComplexDetailInfo(val.projectID)
          if (res) {
            data.roofT1 = res.roofType1;
            data.roofT2 = res.roofType2;
            if (res.roofType1 === 1) {
              res.flatRoofCADResponse1.rooftype = res.roofType1
              data.complexDetailInfoModel[0] = res.flatRoofCADResponse1
              data.complexDetailInfoModel[0].roofData = data.complexDetailInfoModel[0].flatRoofBasicModel
              delete data.complexDetailInfoModel[0].flatRoofBasicModel
            } else if (res.roofType1 === 2 || res.roofType1 === 3 || res.roofType1 === 4) {
              res.obliqueRoofCADResponse1.rooftype = res.roofType1
              data.complexDetailInfoModel[0] = res.obliqueRoofCADResponse1
              data.complexDetailInfoModel[0].roofData = data.complexDetailInfoModel[0].obliqueRoofBasicModel
              delete data.complexDetailInfoModel[0].obliqueRoofBasicModel
            }
            if (res.roofType2 === 1) {
              res.flatRoofCADResponse2.rooftype = res.roofType2
              data.complexDetailInfoModel[1] = res.flatRoofCADResponse2
              data.complexDetailInfoModel[1].roofData = data.complexDetailInfoModel[1].flatRoofBasicModel
              delete data.complexDetailInfoModel[1].flatRoofBasicModel
            } else if (res.roofType2 === 2 || res.roofType2 === 3 || res.roofType2 === 4) {
              res.obliqueRoofCADResponse2.rooftype = res.roofType2
              data.complexDetailInfoModel[1] = res.obliqueRoofCADResponse2
              data.complexDetailInfoModel[1].roofData = data.complexDetailInfoModel[1].obliqueRoofBasicModel
              delete data.complexDetailInfoModel[1].obliqueRoofBasicModel
            }
            for (let i = 0; i< 2; i++) {
              for (const key in data.complexDetailInfoModel[i]) {
                if (key !== 'projectModel' && key !== 'roofData' && key !== 'projectFileRelationModels' && key !== 'rooftype') {
                  if (i === 0) {
                    data.dataAll1[key] =  data.complexDetailInfoModel[i][key]
                  } else if (i === 1) {
                    data.dataAll2[key] =  data.complexDetailInfoModel[i][key]
                  }
                }
              }
            }
            console.log(data.dataAll1 , data.dataAll2, 6767)
            console.log(data.complexDetailInfoModel, 66666666666)
          }
        }
        const { data: imgList } = await GetProjectList(data.infoData.projectID);
        if (imgList.length >0) {
          imgList.forEach(element => {
            if (element.fileType === 1) {
              if (element.contentType === 1 && element.roofIndex === 1) {
                data.paibuImg = element.fileID
              } else if (element.contentType === 2 && element.roofIndex === 1) {
                data.ceshiImg = element.fileID
              } else if (element.contentType === 3 && element.roofIndex === 1) {
                data.houshiImg = element.fileID
                if (data.houshiImg !== '') {
                  houshiShow1.value = true
                }
              } else if (element.contentType === 4 && element.roofIndex === 1) {
                data.jiexianImg = element.fileID
              }
              if (element.contentType === 1 && element.roofIndex === 2) {
                data.paibuImg2 = element.fileID
              } else if (element.contentType === 2 && element.roofIndex === 2) {
                data.ceshiImg2 = element.fileID
              } else if (element.contentType === 3 && element.roofIndex === 2) {
                data.houshiImg2 = element.fileID
                if (data.houshiImg !== '') {
                  houshiShow2.value = true
                }
              } else if (element.contentType === 4 && element.roofIndex === 2) {
                data.jiexianImg2 = element.fileID
              }
            }
          })
        }

        // setTimeout(() => {
        data.dialogVisible = true;
        // },1000)

      },
      // 分页
      handlePageChange(page) {
        data.page = page;
        methodsMap.getProjectList();
      },
      // 排布图
      paibu(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成排布图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.paibuImg)
      },
      // 侧视图
      ceshi(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成侧视图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.ceshiImg)
      },
       // 后视图
      houshi(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成后视图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.houshiImg)
      },
       // 接线图
      jiexian(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成接线图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.jiexianImg)
      },
      // 排布图
      paibu2(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成排布图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.paibuImg2)
      },
      // 侧视图
      ceshi2(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成侧视图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.ceshiImg2)
      },
      // 后视图
      houshi2(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成后视图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.houshiImg2)
      },
      // 接线图
      jiexian2(){
        if (data.paibuImg === '') {
          ElMessage({
            showClose: true,
            message: '该项目未生成接线图.',
            type: 'error',
          })
          return;
        }
        window.open(data.pluginUrl + data.jiexianImg2)
      },
       // bom
      bom(){
        window.open(data.pluginUrl + data.bomImg + '.png')
      }
    };

    return {
      houshiShow1,
      houshiShow2,
      ...toRefs(data),
      ...methodsMap,
      dialogRef,
      // dialogVisible
      ROOF_SUBTYPE,
       ROOF_TYPES,
      RAISE_TYPES,
      COM_BRAND,
      WIND_AREA,
      PROJECT_TYPE,
      COM_MODAL_TYPE,
      INSTALLATION,
      N_ATLAS,
      PULL_BACK_TYPES,
      HOLDER_TYPE,
      NORTH_SOUTH_TYPE,
      INSTALL_METHOD_TYPES,
      WIND_PRESSURE_LEVEL_TYPES,
    };
  }
};
</script>
<style scoped lang="scss">
:deep(.el-row) {
  margin-bottom: 10px;
}
:deep(.el-col) {
  margin-bottom: 10px;
}
:deep(.el-table) {
}
.pagination {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 16px;
}
.look {
  border: 1px solid rgb(219, 219, 219);
  position: absolute;
  top: 10vh;
  left: 20vw;
  z-index: 99;
  background-color: white;
}
.el-descriptions-item {
  margin-left: 150px;
}
/* :deep(.el-input__wrapper) {
  background-color: #ffffff;
  box-shadow: 0 0 0 0;
} */
/*:deep(.el-dialog) {
  //width: 600px;
  width: 60%;
}*/
.el-form-item {
  margin-bottom: 0px;
}
.span1 {
  width: 160px;
  display: inline-block;
  margin-left: 30px;
}
.span4 {
  display: inline-block;
  margin-left: 50px;
  margin-right: 30px;
}
.span3 {
  width: 130px;
  display: inline-block;
  margin-left: 10px;
}
ul li {
  list-style-type: none;
  color: #333333;
  font-weight: 400;
  font-family: 'Arial Normal', 'Arial', sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  margin-top: 15px;
}
:deep(.el-dialog__body) {
  width: 100%;
  height: 500px;
  overflow: hidden !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}
:deep(.el-card) {
  margin-bottom: 10px;
}
.app {
  height: 400px;
  overflow: hidden !important;
  overflow-y: auto !important;
}
.el-scrollbar__wrap {
  /* overflow-x: hidden !important; */
}
.goodForm .el-form-item{
  margin-top: 10px;
}
.bt {
  font-weight: bold;
  font-size: 0.9rem;
}
/*.ob_ul {*/
/*  !*width: 50%;*!*/
/*  display: flex;*/
/*  !*justify-content: space-between;*!*/
/*  justify-content: space-around;*/
/*}*/

/*.ob_ul li {*/
/*  !*width: 100px;*!*/
/*  !*display: inline-block;*!*/
/*}*/
/* .el-dialog{
  width: 600px;
} */
</style>
