const arrToObj = data => {
  return Object.fromEntries(data.map(item => [item.value, item.label]));
};
/**
 * 屋顶类型
 */
export const ROOF_TYPES = [
  {
    label: '平屋顶',
    value: 1
  },
  {
    label: '标准斜屋顶',
    value: 2
  },
  {
    label: '内厦斜屋顶',
    value: 3
  },
  {
    label: '外厦斜屋顶',
    value: 4
  },
  {
    label: '复合屋顶',
    value: 5
  }
];
export const ROOF_TYPES_TEXT = arrToObj(ROOF_TYPES);

/**
 * 复合屋顶类型
 */
export const ROOF_SUBTYPE = [
  {
    label: '平屋顶',
    value: 1
  },
  {
    label: '标准斜屋顶',
    value: 2
  },
  {
    label: '内厦斜屋顶',
    value: 3
  },
  {
    label: '外厦斜屋顶',
    value: 4
  }
];
export const ROOF_SUBTYPE1_TEXT = arrToObj(ROOF_SUBTYPE);

/**
 * 组件品牌 comBrand
 */
export const COM_BRAND = [
  {
    label: '晶澳',
    value: 1
  },
  {
    label: '晶科',
    value: 2
  },
  {
    label: '环晟',
    value: 3
  },
  {
    label: '隆基',
    value: 4
  },
  {
    label: '海泰',
    value: 5
  },
  {
    label: '爱旭',
    value: 6
  }
];
export const COM_BRAND_TEXT = arrToObj(COM_BRAND);

/**
 * 组件品牌 comBrand
 */
export const COM_BRAND_SEARCH = [
  {
    label: '晶澳',
    value: '晶澳'
  },
  {
    label: '晶科',
    value: '晶科'
  },
  {
    label: '环晟',
    value: '环晟'
  },
  {
    label: '隆基',
    value: '隆基'
  },
  {
    label: '海泰',
    value: '海泰'
  },
  {
    label: '爱旭',
    value: '爱旭'
  }
];
export const COM_BRAND_SEARCH_TEXT = arrToObj(COM_BRAND_SEARCH);

/**
 * 组件型号 comModel
 */
export const COM_MODAL_TYPE = [
  {
    label: '540',
    value: '540'
  },
  {
    label: '545',
    value: '545'
  },
  {
    label: '550',
    value: '550'
  },
  {
    label: '600',
    value: '600'
  }
];
export const COM_MODAL_TYPE_TEXT = arrToObj(COM_MODAL_TYPE);

/**
 * 项目类型 comModel
 */
export const PROJECT_TYPE = [
  {
    label: '540',
    value: '540'
  },
  {
    label: '545',
    value: '545'
  },
  {
    label: '550',
    value: '550'
  }
];
export const PROJECT_TYPE_TEXT = arrToObj(COM_MODAL_TYPE);

/**
 * 安装方式
 */
export const INSTALLATION = [
  {
    label: '混泥土基墩',
    value: 1
  },
  {
    label: '膨胀螺栓',
    value: 2
  }
  // {
  //   label: '混合安装',
  //   value: 3
  // }
];
export const INSTALLATION_TEXT = arrToObj(INSTALLATION);

/**
 * 支架类型
 */
export const HOLDER_TYPE = [
  {
    label: 'L字型',
    value: '1'
  },
  {
    label: '7字型',
    value: '2'
  }
];
export const HOLDER_TYPE_TEXT = arrToObj(HOLDER_TYPE);

/**
 * 支架类型（搜索）
 */
export const SUPPORT_TYPE = [
  {
    label: 'L字型',
    value: '1'
  },
  {
    label: '7字型',
    value: '2'
  },
  {
    label: '标准支架',
    value: '3'
  }
];
export const SUPPORT_TYPE_TEXT = arrToObj(SUPPORT_TYPE);

/**
 * 南北排数 northSouthNumber
 */
export const NORTH_SOUTH_TYPE = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '1.5',
    value: '1.5'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '2.5',
    value: '2.5'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '3.5',
    value: '3.5'
  },
  {
    label: '4',
    value: '4'
  },
  {
    label: '4.5',
    value: '4.5'
  },
  {
    label: '5',
    value: '5'
  }
];
export const NORTH_SOUTH_TYPE_TEXT = arrToObj(NORTH_SOUTH_TYPE);

/**
 * 配n图集
 */
export const N_ATLAS = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '1.5',
    value: '1.5'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '2.5',
    value: '2.5'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '3.5',
    value: '3.5'
  },
  {
    label: '4',
    value: '4'
  },
  {
    label: '4.5',
    value: '4.5'
  },
  {
    label: '5',
    value: '5'
  }
];
export const N_ATLAS_TEXT = arrToObj(N_ATLAS);

/**
 * 是否抬高
 */
export const RAISE_TYPES = [
  {
    label: '抬高',
    value: 1
  },
  {
    label: '不抬高',
    value: 2
  }
];
export const RAISE_TYPES_TEXT = arrToObj(RAISE_TYPES);

/**
 * 后拉方式
 */
export const PULL_BACK_TYPES = [
  {
    label: '拉满',
    value: 1
  },
  {
    label: '隔一拉一',
    value: 2
  }
];
export const PULL_BACK_TYPES_TEXT = arrToObj(PULL_BACK_TYPES);

/**
 * 安装方式
 */
export const INSTALL_METHOD_TYPES = [
  {
    label: '混凝土基墩',
    value: 1
  },
  {
    label: '膨胀螺栓',
    value: 2
  }
];
export const INSTALL_METHOD_TYPES_TEXT = arrToObj(INSTALL_METHOD_TYPES);

/**
 * 风压等级 windPressureLevel
 */
export const WIND_PRESSURE_LEVEL_TYPES = [
  {
    label: '低风压',
    value: 1
  },
  {
    label: '中风压',
    value: 2
  },
  {
    label: '高风压',
    value: 3
  }
];
export const WIND_PRESSURE_LEVEL_TYPES_TEXT = arrToObj(WIND_PRESSURE_LEVEL_TYPES);

/**
 * 地区 area
 */
export const WIND_AREA = [
  {
    label: '山东省',
    value: '山东省'
  },
  {
    label: '浙江省',
    value: '浙江省'
  },
  {
    label: '上海市',
    value: '上海市'
  },
  {
    label: '其它',
    value: '其它'
  }
];
export const WIND_AREA_DIQV = arrToObj(WIND_AREA);
